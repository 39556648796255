import React, { useState, useEffect } from "react"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AppStore from "../../images/appstore.svg"
import PlayStore from "../../images/playstore.svg"
import DataTable from "react-data-table-component"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import Modal from "../../components/Modal"
import { WeriseServiceAPI } from "../../services"


const columns = [
  {
    name: "Candidate",

    cell: row => (
      <div className="flex justify-between my-2">
        <div className="messages__user--avatar">
          <img
            src={row?.image_url ? row?.image_url : 'https://res.cloudinary.com/dksyt7yvf/image/upload/v1642258891/blank-profile-picture-973460_1280_lcynag.png'}
            style={{ borderRadius: "50%", width: "200px", height: "50px" }}
            alt=""
          />
        </div>
        <div className="messages__user--name">
          <div className="flex flex-col justify-center" style={{ alignItems: "center" }}>
            <br />
            {row?.first_name && row?.first_name} {row?.last_name && row?.last_name}
            <br />
          </div>
        </div>
      </div>
    ),
    width: "300px",
  },
  {
    name: "Position",
    cell: row => (
      <div className="flex flex-col justify-center" style={{ alignItems: "center" }}>
        <span style={{ color: "#E77C2E" }}>{row?.position}</span>
      </div>
    ),
    width: "200px",
    sortable: true,
  },
  {
    name: "Party",
    cell: row => (
      <div className="flex flex-col justify-center" style={{ alignItems: "center" }}>
        <span
          style={{
            color:
              row?.party?.toLowerCase() === "democratic" ? "#1DA1F2" : "#ff0000",
          }}
        >
          {row.party}
        </span>
      </div>
    ),
    width: "200px",
    sortable: true,
  },
  {
    name: "State",
    cell: row => <div className="flex flex-col justify-center" style={{ alignItems: "center" }}> {row?.state} </div>,
    width: "200px",
    sortable: true,
  },
  {
    name: "District",
    cell: row => <div className="flex flex-col justify-center" style={{ alignItems: "center" }}> {row?.district} </div>,
    width: "200px",
    sortable: true,
  },
  {
    name: "Gender",
    cell: row => (
      <div className="flex flex-col justify-center" style={{ alignItems: "center" }}>{row.gender ? row?.gender[0]?.toUpperCase() : "Not provided"}</div>
    ),
    center: true,
    width: "200px",
    sortable: true,
  },
]
const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={6}
      defaultCenter={{ lat: 43.7844, lng: -88.7879 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      }}
    >
      {
        <Marker
          icon={
            "https://res.cloudinary.com/dksyt7yvf/image/upload/v1638889494/Group_126_bys2zd.png"
          }
          position={{
            lat: Number(43.7844),
            lng: Number(-88.7879),
          }}
        ></Marker>
      }
    </GoogleMap>
  ))
)

export default function Candidate() {
  const [candidates, setCandidates] = useState([])
  const [data, setData] = useState([])
  const [stateSelected, setSelectedState] = useState('')
  const [districtSelected, setSelectedDistrict] = useState('')
  const [countySelected, setSelectedCounty] = useState('')
  const [municipalSelected, setSelectedMunicipal] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const [countyOptions, setCountyOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [municipalOptions, setMunicipalOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])



  // const searchCandidate = async () => {
  //   try {
  //     const res = await WeriseServiceAPI.searchCandidate(stateSelected, municipalSelected, districtSelected, countySelected)
  //     if(res?.status === 200 &&  res?.data?.status === 'success'){
  //       setCandidates(res?.data?.candidates)
  //     }
  //   } catch (error) {
  //     console.error({ error })
  //   }
  // }

  const filterCandidates = (val, type) => {
    let result = [];
    if (type === 'county' && val !== '') {
      result = data.filter((itm) => itm?.county === val)
    }
    if (type === 'district' && val !== '') {
      result = data.filter((itm) => itm?.district === val)
    }
    if (type === 'municipal' && val !== '') {
      result = data.filter((itm) => itm?.municipal === val)
    }
    if (type === 'state' && val !== '') {
      result = data.filter((itm) => itm?.state === val)
    }

    setCandidates(result)
  }

  const getCandidates = async () => {
    try {
      const res = await WeriseServiceAPI.searchCandidate()
      if (res?.status === 200 && res?.data?.status === 'success') {
        setData(res?.data?.candidates)
        console.log('candidates', res?.data?.candidates)
        const duplicateCounties = []
        const duplicateStates = []
        const duplicateMunicpalities = []
        const duplicateDistrict = []

        res?.data?.candidates?.forEach((itm) => {
          if (itm?.county) {
            duplicateCounties.push(itm?.county)
          }
        })
        res?.data?.candidates?.forEach((itm) => {
          if (itm?.state) {
            duplicateStates.push(itm?.state)
          }
        })
        res?.data?.candidates?.forEach((itm) => {
          if (itm?.municipal) {
            duplicateMunicpalities.push(itm?.municipal)
          }
        })
        res?.data?.candidates?.forEach((itm) => {
          if (itm?.district) {
            duplicateDistrict.push(itm?.district)
          }
        })
        setCountyOptions([...new Set(duplicateCounties)])
        setStateOptions([...new Set(duplicateStates)])
        setMunicipalOptions([...new Set(duplicateMunicpalities)])
        setDistrictOptions([...new Set(duplicateDistrict)])
      }
    } catch (error) {
      console.error({ error })
    }
  }

  useEffect(() => {

    getCandidates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadApp  = (url) => {
    if(typeof window !== "undefined"){
      window.open(url, '_blank', "noreferrer, noopener");
    }
  }

  return (
    <>
      <Layout>
        <Seo title="Candidate" />
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="max-w-full xl:min-h-screen flex p-5 flex-col md:flex-col xl:flex-row">
          <div className="flex flex-col xl:w-2/6 hero-pattern bg-hero-pattern p-10 text-center bg-cover">
            <h1 className="antialiased font-bold text-4xl md:text-7xl pt-10 text-white">
              Candidate{" "}
            </h1>
            <span className="py-20 text-md text-left font-medium text-white">
              Get latest news on campaigns and political leaders in your
              district and counties. Get a better a seemless experience with the
              app.
            </span>

            <div className="flex flex-col xl:flex-row xl:justify-around mt-10 justify-between">
              <div onClick={() => downloadApp('https://apps.apple.com/ng/app/werise-empowerment/id1610295267')} className="rounded-2xl border-2  my-2 xl:my-0 bg-white flex items-center justify-center border-primary p-3 cursor-pointer">
                <AppStore />
                {/* <span className="ml-3">App Store</span> */}
                <div className="flex ml-5 gap-1 flex-col">
                  <span className="">App Store</span>
                  {/* <span className="text-xs">Coming Soon</span> */}
                  </div>
              </div>
              <div onClick={() => downloadApp('https://play.google.com/store/apps/details?id=com.werise')} className="rounded-2xl border-2 flex bg-white items-center justify-center border-primary p-3 cursor-pointer">
                <PlayStore />
                <span className="ml-3">Play Store</span>
              </div>
            </div>
          </div>
          <div className="relative w-full h-full">
            <MapWrapper
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8"
              loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
              containerElement={
                <div
                  style={{ height: `800px` }}
                  className="map-canvas"
                  id="map-canvas"
                />
              }
              mapElement={
                <div style={{ height: `100%`, borderRadius: "inherit" }} />
              }
            />

            <div className="absolute bottom-0 h-60 left-0 w-5/6  bg-white bg-opacity-90 z-50">
              <div className="flex w-full xl:flex-row flex-col px-5 xl:p-5">
                <div className="w-full xl:w-1/2 mx-2 my-4 xl:my-2">
                  <select onChange={(e) => {
                    setSelectedState(e.target.value)
                    filterCandidates(e.target.value, 'state')

                  }} value={stateSelected} className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm" name="" id="">
                    <option value="">Select State</option>

                    {stateOptions.map((label, typeIdx) => (
                      <option key={typeIdx} value={label}>{label}</option>
                    ))}
                  </select>

                </div>
                <div className="w-full xl:w-1/2 mx-2 my-4 xl:my-2">
                  <select onChange={(e) => {
                    setSelectedDistrict(e.target.value)
                    filterCandidates(e.target.value, 'district')

                  }} value={districtSelected} className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm" name="" id="">
                    <option value="">Select District</option>
                    {districtOptions.map((label, typeIdx) => (
                      <option key={typeIdx} value={label}>{label}</option>
                    ))}
                  </select>

                </div>
              </div>

              <div className="flex w-full xl:flex-row flex-col px-5 xl:p-5">
                <div className="w-full xl:w-1/2 mx-2 my-4 xl:my-2">
                  <select onChange={(e) => {

                    setSelectedCounty(e.target.value)
                    filterCandidates(e.target.value, 'county')

                  }
                  } value={countySelected} className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm" name="" id="">
                    <option value="">Select County</option>

                    {countyOptions.map((label, typeIdx) => (
                      <option key={typeIdx} value={label}>{label}</option>
                    ))}
                  </select>

                </div>
                <div className="w-full xl:w-1/2 mx-2 my-4 xl:my-2">
                  <select onChange={(e) => {
                    setSelectedMunicipal(e.target.value)
                    filterCandidates(e.target.value, 'municipal')
                  }
                  } value={municipalSelected} className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm" name="" id="">
                    <option value="">Select Municipal</option>

                    {municipalOptions.map((label, typeIdx) => (
                      <option key={typeIdx} value={label}>{label}</option>
                    ))}
                  </select>

                </div>
              </div>
              {/* <div className="flex items-center justify-center">
                <button type="button" disabled={!stateSelected} onClick={() => { }} className="text-white bg-primary rounded-md py-2 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2">
                  Search
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="p-5 mx-w-full my-20">
          <DataTable
            columns={columns}
            // progressPending={isLoading}
            persistTableHead
            // progressComponent={<Loader />}
            data={candidates}
            pagination
          />
        </div>
        <div>
          <Footer />
        </div>
      </Layout>
    </>
  )
}
